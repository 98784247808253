import React from 'react'
import Layout from '../components/layout'
import styled from 'react-emotion'
import { theme } from '../theme'
import { device } from '../utils'
import { ContainerFlex, Text, Link, StickerAnimation } from '../components'

const MessageStickerContainer = styled('div')`
  position: relative;
  width: 100%;
`

const MessageText = styled(Text)`
  font-size: 2em;
  margin-bottom: 0.5em;
  @media ${device.mobileL} {
    font-size: 3em;
  }
`

const StickerAnimationContainer = styled('div')`
  position: absolute;
  width: 50%;
  bottom: -10%;
  right: 10%;
  transform: translate3d(0, 0, 0);
`

const FourOhFourSVG = styled('svg')`
  fill: white;
  width: 100%;
  margin-bottom: 2em;
`

const MessageSticker = () => (
  <MessageStickerContainer>
    <FourOhFourSVG
      x="0px"
      y="0px"
      viewBox="0 0 473.4 197.4"
      fill="currentColor"
    >
      <g>
        <path
          d="M149.4,151.2H129v42.6H81v-42.6H0v-36L64.8,3.6H129v108.6h20.4V151.2z M81,112.2V90.6c0-8,0.4-19.6,1.2-34.8
		c0.4-4.8,0.6-8.2,0.6-10.2h-1.2c-3.2,7.6-8.4,19-15.6,34.2l-17.4,32.4H81z"
        />
        <path
          d="M199.8,186c-10.6-7.6-18.9-18.9-24.8-33.9c-5.9-15-8.8-33.1-8.8-54.3c0-31.8,6.4-56,19.2-72.8c12.8-16.7,30-25,51.6-25
		c14.2,0,26.6,3.7,37.2,11.1c10.6,7.4,18.8,18.4,24.8,33c5.9,14.6,8.8,32.5,8.8,53.7c0,21.2-3,39.3-8.8,54.3
		c-5.9,15-14.2,26.3-24.8,33.9c-10.6,7.6-23,11.4-37.2,11.4C222.8,197.4,210.4,193.6,199.8,186z M253.3,145.2
		c3.9-8.4,5.9-24.2,5.9-47.4c0-16-1-28.2-2.9-36.8c-1.9-8.5-4.5-14.2-7.6-17.1c-3.2-2.9-7.1-4.4-11.7-4.4c-4.6,0-8.5,1.5-11.7,4.4
		c-3.2,2.9-5.8,8.6-7.6,17.1c-1.9,8.5-2.9,20.8-2.9,36.8c0,23.2,2,39,5.9,47.4s9.3,12.6,16.4,12.6
		C244,157.8,249.4,153.6,253.3,145.2z"
        />
        <path
          d="M473.4,151.2H453v42.6h-48v-42.6h-81v-36L388.8,3.6H453v108.6h20.4V151.2z M405,112.2V90.6c0-8,0.4-19.6,1.2-34.8
		c0.4-4.8,0.6-8.2,0.6-10.2h-1.2c-3.2,7.6-8.4,19-15.6,34.2l-17.4,32.4H405z"
        />
      </g>
    </FourOhFourSVG>

    <StickerAnimationContainer>
      <StickerAnimation
        poster="/heart-sticker.svg"
        apng="/stickers/heart-sticker-loop.apng"
      />
    </StickerAnimationContainer>
  </MessageStickerContainer>
)

const NotFoundPage = () => (
  <Layout
    backgroundColor={theme.blue}
    pageTitle="Not Found"
    pageDescription="Not Found"
  >
    <ContainerFlex role="main">
      <MessageSticker />
      <MessageText huge bold>
        You got stuck.
        <br />
        But we got you.
        <br />
        Slap it back <Link to="/">home</Link>.
      </MessageText>
    </ContainerFlex>
  </Layout>
)

export default NotFoundPage
